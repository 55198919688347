import { useHandleAffiliate } from "@presale-app/app/actions/usePressMainButton";
import { SmartContract } from "@thirdweb-dev/react-core";
import cx from "classnames";
import { BaseContract } from "ethers/lib/ethers";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useError, usePercentButton, useUSDC, useUser, useVoucher } from "../../../../hooks";
import { CurrencyName } from "../../../../types";
import { BlueBtn, Input, MintBtn, OutlineBtn, SecondaryBtn } from "../../../../ui-kit";
import Loader from "../../../Loader/Loader";
import { Errors } from "../error.enum";
import { type ModalProps } from "../types";

const TransactionModal: React.FC<ModalProps> = ({ setActiveModal = () => {} }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const cacheRef = useRef<string>("");
  const [inputValue, setInput] = useState("");

  const { setError, parseError } = useError();

  const [error, setErrorMessage] = useState("");

  const { isLoading: usdcLoading, usdcBalance, ethBalance } = useUser();
  const { isLoading: isVoucherLoading, mutateAsync: handleMint } = useHandleAffiliate();
  const { refetch, voucher } = useVoucher();
  const { allowance, approve, isLoading: approveLoading, refetchAllowance } = useUSDC();

  const [activeCurrency, setActiveCurrency] = useState<CurrencyName>("ETH");
  const { getFullCost, getPartCost } = usePercentButton(activeCurrency, voucher);
  const [nextStage, setNextStage] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  const isMintDisabled = useMemo(() => !usdcBalance || usdcLoading, [usdcBalance, usdcLoading]);

  const setInputValue = useCallback(
    (value: string) => {
      if (inputRef.current) {
        inputRef.current.value = value;
        cacheRef.current = value;
      } else {
        cacheRef.current = value;
      }
    },
    [inputRef.current],
  );

  const onValueChangeHandler = () => {
    const errorDesc = validate();
    if (errorDesc) {
      setErrorMessage(errorDesc);
    } else {
      setErrorMessage("");
    }
  };

  useEffect(() => {
    if (allowance >= Number(inputRef.current?.value) && activeCurrency === "USDC") {
      setNextStage(false);
    }

    if (activeCurrency === "ETH") {
      setNextStage(false);
    }

    onValueChangeHandler();
  }, [activeCurrency, inputValue, activeCurrency]);

  const inputRefresh = () => {
    inputRef.current?.focus();
    inputRef.current?.blur();
  };

  const validate = () => {
    if (isNaN(Number(inputRef.current?.value))) {
      setDisabled(true);
      return Errors.WrongValueType;
    }

    if (inputRef.current?.value === "") {
      setDisabled(true);
      return Errors.EmptyField;
    }

    if (
      Number(inputRef.current?.value) > Number(voucher?.availableDepositNumber) &&
      activeCurrency === "USDC"
    ) {
      setDisabled(true);
      return Errors.MoreThanMaxDep;
    }

    if (
      Number(inputRef.current?.value) > Number(voucher?.availableDepositNumberInETH) &&
      activeCurrency === "ETH"
    ) {
      setDisabled(true);
      return Errors.MoreThanMaxDep;
    }

    if (Number(inputRef.current?.value) <= 0) {
      setDisabled(true);
      return Errors.NegativeNumber;
    }

    if (Number(inputRef.current?.value) > Number(usdcBalance) && activeCurrency === "USDC") {
      setDisabled(true);
      return Errors.InvalidAmount;
    }

    if (
      inputRef.current?.value &&
      inputRef.current.value.split(".").length === 2 &&
      inputRef.current.value.split(".")[1].length > 6 &&
      activeCurrency === "USDC"
    ) {
      setDisabled(true);
      return Errors.ExceedsDecimals;
    }

    if (allowance < Number(inputRef.current?.value) && activeCurrency === "USDC") {
      setDisabled(true);
      setNextStage(true);
      return Errors.NeedApprove;
    }

    if (Number(inputRef.current?.value) > Number(ethBalance) && activeCurrency === "ETH") {
      setDisabled(true);
      return Errors.InvalidAmount;
    }

    setDisabled(false);
    return "";
  };

  const handleMintClick = useCallback(
    (contract: SmartContract<BaseContract>) => {
      cacheRef.current = inputRef.current?.value || "";

      return handleMint({
        contract,
        currency: activeCurrency,
        isHaveVoucher: !!voucher?.currentVoucher,
        value: inputRef?.current?.value.trim() || "0",
      });
    },
    [allowance, activeCurrency, !!voucher?.currentVoucher],
  );

  if (isVoucherLoading || approveLoading) {
    return (
      <div className="fixed top-0 left-0 flex justify-center items-center w-full h-full">
        <Loader />
      </div>
    );
  }

  return (
    <div className="fixed bg-black/70 top-0 left-0 flex justify-center items-center w-full h-full">
      <div className="w-[700rem] bg-vitreus-green-800 rounded-large animate-fade-in relative mx-20">
        <div
          className="absolute left-full top-0 w-24 h-24 ml-20 cursor-pointer"
          onClick={() => setActiveModal("")}
        >
          <img src="../../../../../assets/images/cross.svg" alt="" />
        </div>
        <div className="px-24 py-48">
          <div className="flex items-start gap-16">
            <Input
              className="flex-auto"
              ref={inputRef}
              onChange={setInput}
              value={inputValue}
              error={error}
              defaultValue={cacheRef.current}
              onValueChangeHandle={onValueChangeHandler}
              activeCurrency={activeCurrency}
            />
            <div className="text-p1 text-white h-48 flex items-center">
              <span
                className={cx("cursor-pointer mr-8", {
                  "text-gray": activeCurrency !== "ETH",
                  "underline text-[20rem] leading-[20rem]": activeCurrency === "ETH",
                })}
                onClick={() => {
                  setActiveCurrency("ETH");
                }}
              >
                ETH
              </span>
              <span
                className={cx("cursor-pointer", {
                  "text-gray": activeCurrency !== "USDC",
                  "underline text-[20rem] leading-[20rem]": activeCurrency === "USDC",
                })}
                onClick={() => {
                  setActiveCurrency("USDC");
                }}
              >
                USDC
              </span>
            </div>
          </div>
          <div className="mt-16 mb-48 gap-8 flex flex-wrap items-center text-p1 text-gray">
            <MintBtn
              className="oneHundredPercent"
              text="100 %"
              onClick={async (contract) => {
                if (activeCurrency === "ETH") {
                  const estimation = await handleMint({
                    contract,
                    currency: activeCurrency,
                    isHaveVoucher: !!voucher?.currentVoucher,
                    value: "0.00001",
                    isEstimate: true,
                  });

                  setInputValue(getFullCost(estimation));
                  setInput(getFullCost(estimation));
                } else {
                  setInputValue(
                    String(Math.min(Number(voucher?.availableDepositNumber), Number(usdcBalance))),
                  );
                  inputRefresh();
                }
              }}
            />
            <OutlineBtn
              text="75 %"
              onClick={async () => {
                const part = await getPartCost(75);
                setInputValue(part);
                setInput(part);
                inputRef.current?.focus();
              }}
            />
            <OutlineBtn
              text="50 %"
              onClick={async () => {
                const part = await getPartCost(50);
                setInputValue(part);
                setInput(part);
                inputRef.current?.focus();
              }}
            />
            <OutlineBtn
              text="25 %"
              onClick={async () => {
                const part = await getPartCost(25);
                setInputValue(part);
                setInput(part);
                inputRef.current?.focus();
              }}
            />
            of your balance
          </div>
        </div>
        {!nextStage ? (
          <div className="grid grid-cols-2">
            <SecondaryBtn
              className="rounded-bl-large"
              text="cancel"
              onClick={() => setActiveModal("")}
              rounded={false}
            />
            <MintBtn
              className="rounded-br-large doMint"
              text="mint"
              refresh={inputRefresh}
              onClick={(e) => {
                if (validate()) return;
                return handleMintClick(e);
              }}
              rounded={false}
              onSuccess={async (e) => {
                refetch();
                setActiveModal("successModal");
              }}
              onError={(e) => {
                setError(parseError(e));
                setActiveModal("errorModal");
              }}
              disabled={isMintDisabled || disabled}
            />
          </div>
        ) : (
          <BlueBtn
            text="approve"
            className="w-full"
            disabled={disabled}
            onClick={async () => {
              try {
                await approve({
                  value: String((Number(inputRef.current?.value) + 0.01).toFixed(6)).trim() || "0",
                });
                await refetchAllowance();
                setActiveModal("approveModal");
              } catch (e) {
                setError(parseError(e));
                setActiveModal("errorModal");
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default TransactionModal;
