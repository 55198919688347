import cx from "classnames";

import { ButtonProps } from "../ui-kit-types";

const OutlineBtn: React.FC<ButtonProps> = ({ className, onClick, text, fullWidth }) => {
  return (
    <button
      className={cx(
        "text-p3 uppercase border border-vitreus-leaf text-vitreus-leaf py-12 px-14 rounded-small hover:text-vitreus-luminous-green-100 hover:border-vitreus-luminous-green-100 transition-colors duration-300",
        className,
        {
          "w-full": fullWidth,
        },
      )}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default OutlineBtn;
