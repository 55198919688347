import { useAddress, useSDK } from "@thirdweb-dev/react";

import { useGetDeposits } from "../actions/useGetDeposits";
import { useGetVoucher } from "../actions/useGetVoucher";

export const useVoucher = () => {
  const sdk = useSDK();
  const address = useAddress();

  const {
    data: voucher,
    isLoading: isVoucherLoading,
    refetch: refetchVoucher,
  } = useGetVoucher(sdk?.getProvider(), address);

  const {
    data: deposits,
    isLoading: isDepositsLoading,
    refetch: refetchDeposits,
  } = useGetDeposits(voucher?.presaleRounds, sdk?.getProvider());

  return {
    voucher,
    isVoucherLoading,
    refetch: () => {
      refetchVoucher();
      refetchDeposits();
    },
    deposits,
    isDepositsLoading,
  };
};
