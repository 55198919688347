// eslint-disable-next-line @nx/enforce-module-boundaries
import { OutlineBtn } from "@presale-app/app/ui-kit";

import { type ModalProps } from "../types";

const SuccessModal: React.FC<ModalProps> = ({ setActiveModal = () => {} }) => {
  return (
    <div className="fixed top-0 left-0 flex justify-center items-center w-full h-full">
      <div className="w-[420rem] bg-vitreus-green-800 rounded-large py-64 px-40 flex flex-col items-center mx-20">
        <img src="../../../../../assets/images/check.svg" alt="" />
        <span className="text-sh2 text-vitreus-luminous-green my-16 text-center">
          Transaction successful
        </span>
        <span className="text-p1 text-gray mb-60 text-center">
          Congratulations! You successfully received the VTRS on your Vitreus voucher.
        </span>
        <OutlineBtn text="Close" onClick={() => setActiveModal("")} fullWidth />
      </div>
    </div>
  );
};

export default SuccessModal;
