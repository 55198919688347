import { useCallback } from "react";

import { floor } from "../helpers";
import { CurrencyName, Voucher } from "../types";
import { useUser } from "./useUser";

export const usePercentButton = (currency: CurrencyName, voucher: Voucher) => {
  const { isLoading, ethBalance, usdcBalance } = useUser();

  const getFullCost = useCallback(
    (estimation: string) => {
      // TODO_ENV: 5 - environment: ETH_DECIMALS
      const usdcMaxDepositInETH = voucher.availableDepositNumberInETH;
      const expectedPercent = Number(ethBalance) - Number(estimation);

      if (expectedPercent < 0) {
        return "0";
      }

      return expectedPercent < usdcMaxDepositInETH
        ? floor(expectedPercent, 5)
        : floor(usdcMaxDepositInETH, 5);
    },
    [ethBalance, usdcBalance],
  );

  const getPartCost = useCallback(
    (percent: number) => {
      if (currency === "ETH") {
        // TODO_ENV: 5 - environment: ETH_DECIMALS
        // eth percent
        const expectedPercent = (Number(ethBalance) * percent) / 100;
        const usdcMaxDepositInETH = voucher.availableDepositNumberInETH;

        return expectedPercent < usdcMaxDepositInETH
          ? floor(expectedPercent, 5)
          : floor(usdcMaxDepositInETH, 5);
      } else {
        // TODO_ENV: 2 - environment: MAIN_USDC_DECIMALS
        const expectedPercent = (Number(usdcBalance) * percent) / 100;
        const maxDepositUSDC = Number(voucher.availableDepositNumber);

        return expectedPercent < maxDepositUSDC
          ? floor(expectedPercent, 2)
          : floor(maxDepositUSDC, 2);
      }
    },
    [currency, ethBalance, usdcBalance],
  );

  return { getFullCost, getPartCost, isLoading };
};
