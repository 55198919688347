import { formatWithLetter } from "@presale-app/app/helpers";

import { config } from "../../config/environment.config";
import { useVoucher } from "../../hooks/useVoucher";
import Loader from "../Loader/Loader";

const StatBlock: React.FC = () => {
  const { voucher } = useVoucher();

  if (!voucher) {
    return <Loader className="p-20" />;
  }

  return (
    <div className="text-gray text-p1 p-24 rounded-large bg-vitreus-green-800 my-24 ">
      <div className="flex gap-8 sm:flex-wrap">
        {/* TODO: remove hardcode */}
        {/* <span className="sm:flex-[0_1_100%]">{`Presale Stage ${voucher.currentPresaleRoundNumber} of ${voucher.allPresaleRoundCount}`}</span> */}
        <span className="sm:flex-[0_1_100%]">{`Presale Stage ${10} of ${10}`}</span>
        <span className="m-0 smin:hidden">
          Sold{" "}
          <span className="text-white">
            {formatWithLetter(voucher.currentPresaleRoundSoldVtrs)}
          </span>
          <span className="text-p3 ml-4">VTRS</span>
        </span>
        <span className="smin:hidden">
          of{" "}
          <span className="text-white">{formatWithLetter(voucher.currentPresaleRoundAllVtrs)}</span>
          <span className="text-p3 ml-4">VTRS</span>
        </span>

        <span className="ml-auto sm:hidden">
          Sold <span className="text-white">{voucher.currentPresaleRoundSoldVtrs}</span>
          <span className="text-p3 ml-4">VTRS</span>
        </span>
        <span className="sm:hidden">
          of <span className="text-white">{voucher.currentPresaleRoundAllVtrs}</span>
          <span className="text-p3 ml-4">VTRS</span>
        </span>
        <span>
          <span className="text-white">{`(${voucher.vtrsSoldPercentage}%)`}</span>
        </span>
      </div>
      <div className="rounded-full bg-vitreus-gradient-horizontal h-8 flex justify-end my-32">
        <div
          className="rounded-full bg-vitreus-green-800 m-1"
          style={{
            width: `${Math.min(100 - voucher.currentPresaleRound.progress * 100, 95)}%`,
          }}
        ></div>
      </div>
      <div className="grid grid-cols-2 gap-y-16 sm:flex sm:flex-col sm:gap-8">
        <div>
          Start
          <span className="ml-16 text-white">
            {voucher.currentPresaleRoundNumber === "1"
              ? "June 24th, 04:00PM"
              : voucher.currentPresaleRound.startTime}
          </span>
        </div>
        <div className="sm:order-1">
          Discount <span className="ml-16 text-white">{voucher.currentPresaleRoundDiscount}%</span>
        </div>
        <div>
          {/* TODO: remove hardcode */}
          {/* End <span className="ml-16 text-white">{voucher.currentPresaleRound.endTime}</span> */}
          End <span className="ml-16 text-white">TBD</span>
        </div>
        <div className="sm:order-2">
          Max Deposit
          <span className="ml-16 text-white">${voucher.availableDeposit}</span>
        </div>
        <div>
          Current Price
          <span className="ml-16 text-white">${voucher.currentPresaleRoundPrice}</span>
        </div>
        <div className="sm:order-3">
          Total Proceeds
          <span className="ml-16 text-white">${voucher.totalProceeds}</span>
        </div>
        <div>
          Next Stage <span className="ml-16 text-white">${voucher.nextPrice}</span>
        </div>
        <div className="flex items-center sm:order-4">
          View Contracts/NFTs
          <span className="flex">
            <a
              href={`${config.ETHERSCAN_LINK}${config.VOUCHER_ADDRESS}`}
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-24 ml-16" src="./assets/images/etherscan-logo-circle.svg" alt="" />
            </a>
            <a
              href={`${config.OPENSEA_LINK}${config.VOUCHER_ADDRESS}`}
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-24 ml-16" src="./assets/images/open-sea-logo.svg" alt="" />
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default StatBlock;
