import { useBalance } from "@thirdweb-dev/react";

import { config } from "../config/environment.config";
import { floor } from "../helpers";

export const useUser = () => {
  const { data: ethBalance, isLoading: isEthLoading } = useBalance();
  const { data: usdcBalance, isLoading: isUsdcLoading } = useBalance(config.USDC_ADDRESS);

  if (!ethBalance || !usdcBalance) {
    return { ethBalance: "0", usdcBalance: "0" };
  }

  // TODO_ENV: 2 - environment: MAIN_USDC_DECIMALS
  // TODO_ENV: 5 - environment: ETH_DECIMALS
  return {
    ethBalance: floor(Number(ethBalance.displayValue), 5),
    usdcBalance: floor(Number(usdcBalance.displayValue), 2),
    isLoading: isEthLoading || isUsdcLoading,
  };
};
