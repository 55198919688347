export enum Errors {
  NeedApprove = "Need approval",
  InvalidAmount = "Invalid amount. Entered amount is more than available on your wallet address",
  EmptyField = "Invalid amount. Please enter valid sum",
  WrongValueType = "Invalid amount. Please enter numeric value",
  NegativeNumber = "Invalid amount. Entered amount is less than minimum able deposit",
  TimeIsBeforeStart = "Get ready for our first presale stage! We're thrilled to announce that our first presale stage is coming soon. Prepare yourself for an incredible investment opportunity",
  TimeIsBetweenStages = "Another chance to invest! Following the conclusion of the previous presale stage, we're excited to inform you that the next stage is right around the corner. Get ready to seize the opportunity!",
  TimeIsUp = "Time limit exceeded. The presale stages have concluded as per the set duration. Thank you for your contribution!",
  MoreThanMaxDep = "Invalid amount. Entered amount is more than maximum able deposit",
  UserRejectedTransaction = "Rejected transaction. You have chosen to decline the transaction, and it will not be executed.",
  Overflow = "Overflow. Ethereum transaction processing. Please wait, it may take some time for completion. To check transaction status you may use your wallet or blockchain explorer.",
  Reconnect = "Error. Connection loosed. Please reconnect your Ethereum wallet and try again.",
  ExceedsDecimals = "The maximum allowed number of characters after a dot is 6",
  UnknownError = " Something went wrong! Reload page and try again",
}
