import { utils } from "ethers";

import { config } from "../../config/environment.config";
import { commifyWithDecimals, floor, formatUsdcFloor } from "../../helpers";
import { PresaleRound } from "../PresaleRound";
import { IDeposit } from "./types";

export class Deposit implements IDeposit {
  round: string;
  id: string;
  amount: string;
  allocation: string;
  depositId?: string;

  constructor(presaleRounds: Record<string, PresaleRound>, args?: any, depositId?: string) {
    this.depositId = depositId;
    this.amount = formatUsdcFloor(args?.amount);
    this.id = utils.formatUnits(args?.id, 0);
    this.round = utils.formatUnits(args?.round, 0);
    // TODO_ENV: 2 - environment: VTRS_DECIMALS
    this.allocation = commifyWithDecimals(
      +floor(
        Number(utils.formatUnits(args?.amount, config.USDC_DECIMALS)) /
          Number(presaleRounds[this.round].price),
        2,
      ),
      2,
    );
  }
}
