import { CurrencyName } from "@presale-app/app/types";
import cx from "classnames";
import { forwardRef, useEffect } from "react";

type InputProps = {
  className?: string;
  defaultValue?: string;
  onValueChangeHandle?: () => void;
  onChange?: (e: any) => void;
  error?: string;
  value?: string;
  activeCurrency?: CurrencyName;
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { className, defaultValue, onChange, activeCurrency, value, onValueChangeHandle, error },
    ref,
  ) => {
    useEffect(() => {
      onValueChangeHandle && onValueChangeHandle();
    }, [activeCurrency]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onValueChangeHandle && onValueChangeHandle();
      onChange && onChange(e.target.value);
    };

    return (
      <div className={cx("relative", className)}>
        <input
          defaultValue={defaultValue}
          ref={ref}
          onChange={handleChange}
          className={cx(
            "text-p1 py-12 px-16 rounded-small bg-vitreus-green-600 outline-none text-white w-full",
            {
              "border-transparent border": !error,
              "border-vitreus-red border": !!error && !!value?.length,
            },
          )}
          onBlur={onValueChangeHandle}
        ></input>
        <span className="text-vitreus-red text-p1 mt-4 w-full h-32 inline-block">
          {error && !!value?.length ? error : ""}
        </span>
      </div>
    );
  },
);

export default Input;
