import React, { ReactElement } from "react";

import {
  ApproveModal,
  DisclaimerModal,
  ErrorModal,
  SuccessModal,
  TransactionModal,
} from "./components";

type ModalsContainerProps = {
  activeModal?: string;
  setActiveModal: React.Dispatch<React.SetStateAction<string>>;
};

const modalList: Record<string, ReactElement> = {
  transactionModal: <TransactionModal />,
  successModal: <SuccessModal />,
  errorModal: <ErrorModal />,
  disclaimerModal: <DisclaimerModal />,
  approveModal: <ApproveModal />,
};

const ModalsContainer: React.FC<ModalsContainerProps> = ({ activeModal, setActiveModal }) => {
  if (!activeModal) return <div></div>;

  return <div>{React.cloneElement(modalList[activeModal], { setActiveModal })}</div>;
};

export default ModalsContainer;
