import { BigNumber, utils } from "ethers";
import moment from "moment";

import { config } from "../config/environment.config";

export const formatUsdc = (value: BigNumber) =>
  utils.commify(
    Number(utils.formatUnits(value, config.USDC_DECIMALS)).toFixed(config.DECIMAL_VALUE),
  );

export const formatUsdcFloor = (value: BigNumber) => {
  let result = utils.commify(
    // TODO_ENV: 2 - environment: MAIN_USDC_DECIMALS
    (
      +String(+utils.formatUnits(value, config.USDC_DECIMALS) * pow(10, 2)).split(".")[0] /
      pow(10, 2)
    ).toFixed(2),
  );
  for (let i = result.split(".")[1]?.length || 0; i < 2; i++) {
    result += "0";
  }
  return result;
};

export const floor = (value: number, decimals: number) =>
  (+String(value * pow(10, decimals)).split(".")[0] / pow(10, decimals)).toFixed(decimals);

export const formatTime = (value: BigNumber) =>
  moment(Number(utils.formatUnits(value, 0)) * 1000).format(config.DATE_FORMAT);

export const round = (value: number, decimals: number) => {
  let result = utils.commify(value.toFixed(decimals));
  for (let i = result.split(".")[1]?.length || 0; i < decimals; i++) {
    result += "0";
  }
  return result;
};

export const formatWithLetter = (commifyValue: string) => {
  const discharge = commifyValue.split(",").length - 1;
  let letter = "";

  switch (discharge) {
    case 1:
      letter = "K";
      break;
    case 2:
      letter = "M";
      break;
    case 3:
      letter = "B";
      break;
    default:
      letter = "";
      break;
  }

  return commifyValue.split(",")[0] + letter;
};

export const commifyWithDecimals = (value: number, decimals: number) => {
  let result = utils.commify(value);
  if (result.split(".")[1]?.length) {
    for (let i = result.split(".")[1].length; i < decimals; i++) {
      result += "0";
    }
  }
  return result;
};

export const pow = (x: number, n: number) => {
  let result = x;
  for (let i = 1; i < n; i++) {
    result *= x;
  }
  return result;
};
