import cx from "classnames";

import { Deposit } from "../../../models/Deposit";

interface VoucherProps extends Deposit {
  className?: string;
}

const Voucher: React.FC<VoucherProps> = ({ className, amount, id, allocation }) => {
  return (
    <div
      className={cx(
        "flex items-center py-16 px-32 rounded-large border border-vitreus-green-800 sm:px-16",
        className,
      )}
    >
      <div className="mr-20">
        <img src="../../../../assets/images/logo-gray.svg" alt="" />
      </div>
      <div className="flex items-center flex-auto sm:block">
        <div className="mr-8">
          <div className="mb-8">
            Voucher <span className="text-white">#{id}</span>
          </div>
          <div>
            Allocation <span className="text-white">{allocation}</span>
            <span className="uppercase text-p3 ml-8">vtrs</span>
          </div>
        </div>
        <div className="text-white ml-auto mt-8">${amount}</div>
      </div>
    </div>
  );
};

export default Voucher;
