import { ConnectWalletBtn, Link } from "../../ui-kit";
import WidthWrapper from "../WidthWrapper/WidthWrapper";

//1270px

const Header = () => {
  return (
    <header className="bg-vitreus-green-800 border-b-vitreus-green-400 border-b fixed w-full z-10 top-0">
      <WidthWrapper className="py-20 flex ">
        <div className="sm:hidden">
          <img src="../../../assets/images/logo.svg" alt="" />
        </div>
        <div className="hidden sm:block">
          <img className="h-32" src="../../../assets/images/mark-full-color.svg" alt="" />
        </div>
        <Link
          className="ml-auto uppercase sm:hidden"
          to="https://vitreus.io/nft-purchase-agreement/"
          text="NFT Purchase Agreement"
          blank
        />
        <Link
          className="ml-24 uppercase sm:hidden"
          to="https://vitreus.io/kyc-and-audit/"
          text="KYC & Audit"
          blank
        />
        <Link
          className="ml-24 uppercase sm:hidden"
          to="https://vitreus.io/faq-presale/"
          text="FAQ"
          blank
        />
        <ConnectWalletBtn className="ml-24 sm:ml-auto" />
      </WidthWrapper>
    </header>
  );
};

export default Header;
