import { useQuery } from "@tanstack/react-query";
import { SmartContract } from "@thirdweb-dev/sdk";
import { BaseContract } from "ethers/lib/ethers";

import { config } from "../config/environment.config";

export const useGetUSDC = (contract?: SmartContract<BaseContract>) =>
  useQuery(
    ["getUSDC"],
    async () => {
      return await contract?.erc20.allowance(config.VOUCHER_ADDRESS);
    },
    { enabled: !!contract },
  );
