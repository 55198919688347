import { QueryClientProvider } from "@tanstack/react-query";
import { ArbitrumSepolia } from "@thirdweb-dev/chains";
import {
  coinbaseWallet,
  metamaskWallet,
  safeWallet,
  ThirdwebProvider,
  walletConnect,
} from "@thirdweb-dev/react";
import { IpfsUploader, StorageDownloader, ThirdwebStorage } from "@thirdweb-dev/storage";
import { Navigate, Outlet, RouterProvider } from "react-router";
import { createBrowserRouter } from "react-router-dom";

import { AffiliateInitializer } from "./components/AffiliateInitializer/AffiliateInitializer";
import { ContextProviders } from "./components/Context";
import { queryClient } from "./config/queryClient.config";
import { FAQ } from "./pages/Faq";
import { KYC } from "./pages/KYC";
import { Landing } from "./pages/Landing";

export enum Routes {
  Home = "/",
  FAQ = "/faq",
  KYC = "/kyc",
  Landing = "/landing",
}

const downloader = new StorageDownloader({});
const uploader = new IpfsUploader({ uploadWithGatewayUrl: true });
const storage = new ThirdwebStorage({
  uploader,
  downloader,
  gatewayUrls: [
    "https://w3s.link/ipfs/",
    "https://gateway.ipfscdn.io/ipfs/",
    "https://cloudflare-ipfs.com/ipfs/",
    "https://ipfs.io/ipfs/",
  ],
});

const ProvidersComponent = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThirdwebProvider
        dAppMeta={{
          name: "Vitreus Presale dApp",
          logoUrl:
            "https://bafybeif23wbgdywp55lyj3p7os2bzphuwyuhrq53f3jz5hu7aq6pgmukam.ipfs.w3s.link/vitreus-logo.png",
          url: "https://presale.vitreus.io/",
          description: "Vitreus Presale dApp",
        }}
        storageInterface={storage}
        activeChain={{ ...ArbitrumSepolia, faucets: ["https://arbitrum-faucet.com/"] }}
        supportedWallets={[
          metamaskWallet(),
          coinbaseWallet(),
          walletConnect(),
          // walletConnect({ projectId: config.WALLET_CONNECT_PROJECT_ID }),
          safeWallet(),
        ]}
        autoSwitch
      >
        <ContextProviders>
          <Outlet />
        </ContextProviders>
      </ThirdwebProvider>
    </QueryClientProvider>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <ProvidersComponent />,
    children: [
      {
        path: "/",
        element: (
          <AffiliateInitializer>
            <Outlet />
          </AffiliateInitializer>
        ),
        children: [
          { path: Routes.Home, element: <Landing />, index: true },
          { path: Routes.FAQ, element: <FAQ /> },
          { path: Routes.KYC, element: <KYC /> },
        ],
      },
    ],
  },
  { path: "*", element: <Navigate to={"/"} /> },
]);

// TODO: images, layouts, loader, animations

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
