import React from "react";

const BackImages = () => {
  return (
    <>
      <img
        className="fixed right-0 top-0 w-[37vw] sm:w-[100vw]"
        src="../../../assets/images/spot_1.png"
      />
      <img
        className="fixed left-0 top-2/3 -translate-y-1/2 w-[37vw] sm:w-[80vw]"
        src="../../../assets/images/spot_2.png"
      />
    </>
  );
};

export default BackImages;
