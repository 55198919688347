import { useVoucher } from "../../hooks/useVoucher";
import Loader from "../Loader/Loader";

const VoucherBig: React.FC = () => {
  const { voucher } = useVoucher();

  if (!voucher) {
    return <Loader className="p-20" />;
  }

  if (!voucher.currentVoucher) {
    return null;
  }

  return (
    <div className="bg-vitreus-gradient-horizontal text-p1 p-1 rounded-large text-gray sm:text-p3">
      <div className="bg-black rounded-large flex p-24 pr-64 gap-24 items-center justify-between sm:p-12 sm:gap-8">
        <div className="w-80 h-80 flex items-center justify-center sm:h-60">
          <img className="w-48" src="../../../assets/images/mark-full-color.svg" alt="" />
        </div>
        <div>
          <div>Voucher</div>
          <div className="text-white mt-8">#{voucher.currentVoucher.id}</div>
        </div>
        <div>
          <div>Allocation</div>
          <div className="text-white mt-8">
            {voucher.currentVoucher.allocation}{" "}
            <span className="uppercase text-gray text-p3">vtrs</span>
          </div>
        </div>
        <div>
          <div>Value</div>
          <div className="text-white mt-8">
            {voucher.currentVoucher.value} <span className="uppercase text-gray text-p3">USD</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoucherBig;
