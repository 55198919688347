import { createContext, Dispatch, FC, ReactElement, useContext, useState } from "react";

import { Errors } from "../components/ModalsContainer/components/error.enum";

const parseError = (e: any) => {
  if (e.reason === "user rejected transaction") {
    return {
      ...e,
      reason: Errors.UserRejectedTransaction,
      message: Errors.UserRejectedTransaction,
    };
  }
  if (e.reason.includes("overflow")) {
    return {
      ...e,
      reason: Errors.Overflow,
      message: Errors.Overflow,
    };
  }
  if (e.reason.includes("please call connect() before request()")) {
    return {
      ...e,
      reason: Errors.Reconnect,
      message: Errors.Reconnect,
    };
  }
  if (e.message.includes("undefined")) {
    return {
      ...e,
      reason: Errors.UnknownError,
      message: Errors.UnknownError,
    };
  }

  return e;
};

export const ErrorContext = createContext({
  error: null as Error | null | any,
  setError: (() => null) as Dispatch<React.SetStateAction<Error | null>>,
  parseError: parseError,
});
export const useError = () => useContext(ErrorContext);

export const ErrorProvider: FC<{ children: ReactElement | ReactElement[] }> = ({ children }) => {
  const [error, setError] = useState<Error | null>(null);

  return (
    <ErrorContext.Provider value={{ error, setError, parseError }}>
      {children}
    </ErrorContext.Provider>
  );
};
