import { useVoucher } from "@presale-app/app/hooks";
import { OutlineBtn } from "@presale-app/app/ui-kit";

import BalanceBlock from "../BalanceBlock/BalanceBlock";
import Loader from "../Loader/Loader";
import ModalsContainer from "../ModalsContainer/ModalsContainer";
import StatBlock from "../StatBlock/StatBlock";
import VoucherBig from "../VoucherBig/VoucherBig";
import VoucherList from "../VoucherList/VoucherList";
import WidthWrapper from "../WidthWrapper/WidthWrapper";

type MainProps = {
  className?: string;
  activeModal: string;
  setActiveModal: React.Dispatch<React.SetStateAction<string>>;
};

const Main: React.FC<MainProps> = ({ className, activeModal, setActiveModal }) => {
  const { voucher } = useVoucher();

  if (!voucher) {
    return <Loader className="p-20" />;
  }

  return (
    <main className={className}>
      <WidthWrapper className="max-w-small pb-[300rem]">
        <BalanceBlock
          onClick={() => {
            // before all
            // if (
            //   1687615200 * 1000 > Date.now() &&
            //   voucher.currentPresaleRoundNumber === "1"
            // ) {
            //   setError(Error(Errors.TimeIsBeforeStart));
            //   setActiveModal("errorModal");
            //   return;
            // }

            // // after all
            // if (
            //   Number(voucher.currentPresaleRound.endTimeDate) * 1000 < Date.now() &&
            //   voucher.currentPresaleRoundNumber === "10"
            // ) {
            //   setError(Error(Errors.TimeIsUp));
            //   setActiveModal("errorModal");
            //   return;
            // }

            // // all the rest
            // if (
            //   Number(voucher.currentPresaleRound.startTimeDate) * 1000 > Date.now() ||
            //   Number(voucher.currentPresaleRound.endTimeDate) * 1000 < Date.now()
            // ) {
            //   setError(Error(Errors.TimeIsBetweenStages));
            //   setActiveModal("errorModal");
            //   return;
            // }
            setActiveModal("transactionModal");
          }}
        />
        <StatBlock />
        <VoucherBig />
        <VoucherList />
        <OutlineBtn
          className="uppercase fixed bottom-32 right-32 bg-black"
          text="go to top"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        />
        <ModalsContainer activeModal={activeModal} setActiveModal={setActiveModal} />
      </WidthWrapper>
    </main>
  );
};

export default Main;
