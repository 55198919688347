export const environment = {
  production: true,
  environment:  `presale-testnet`,
  SUPABASE_URL:  `https://czonnletnhmsyxbhujyt.supabase.co`,
  SUPABASE_ANON_KEY: `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImN6b25ubGV0bmhtc3l4Ymh1anl0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODgzOTQ1NTQsImV4cCI6MjAwMzk3MDU1NH0.0f4MUXefV8kkK0YdTx4WeDRa_4RRuCsO3Lm56RLlRcM`,
  USDC_ADDRESS: `0x3A872f3528c8100D551558cFAA2e7b9D32d979E8`,
  VOUCHER_ADDRESS: `0xa1FC0Dce5f29d56B8D905B4fF5AeF18a5cf3B3Bf`,
  ETHERSCAN_LINK: `https://sepolia.arbiscan.io/address/`,
  OPENSEA_LINK: `https://testnets.opensea.io/assets/arbitrum-sepolia/`,
  DECIMAL_VALUE: 4,
  USDC_DECIMALS: 6,
  MAIN_USDC_DECIMALS: 2,
  ETH_DECIMALS: 5,
  ROUND_PRICE_DECIMALS: 4,
  PERCENTAGE_DECIMALS: 2,
  VTRS_DECIMALS: 2,
  DATE_FORMAT: "MMMM Do, hh:mmA",
  LAUNCH_PRICE: 50,
  ACTIVE_CHAIN: `arbitrum-sepolia`,
  DEPOSIT_GAS_LIMIT: `210000`,
  MINT_GAS_LIMIT: `270000`,
  AFFILIATE_DEPOSIT_GAS_LIMIT: `210000`,
  AFFILIATE_MINT_GAS_LIMIT: `270000`,
  apiUrl: `https://presale-testnet-be.compliq.io/indexer-api`,
  WALLET_CONNECT_PROJECT_ID: `02facf3bce40e84f9d62da04f8707b6e`,
  INFURA: `12881bed616c4a42a3141f465b3cb9a8`,
  AFFILIATE_API_URL: `https://vitreus-affiliate-dev.compliq.io:815`,
  RESTRICTED_PRESALE_HOST: `presale-testnet.compliq.io`,
  SENTRY_DSN: `https://5abf519b80dad54d6bac46e7f033cdd2@o4505596624502784.ingest.sentry.io/4505647443214336`,
};
