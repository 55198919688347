import { environment } from "@presale-app/environments/environment";

import { config } from "../config/environment.config";
import { supabase } from "../config/supabase.config";

export const getConfig = async (): Promise<typeof config> => {
  try {
    const { data } = await supabase
      .from("presale_config")
      .select("*")
      .eq("environment", environment.environment);

    const clearData =
      data && data.length
        ? Object.entries(data[0]).reduce((object, [key, value]) => {
            if (value) {
              object[key] = value as string;
            }

            return object;
          }, {} as Record<string, string>)
        : {};

    return { ...config, ...clearData };
  } catch (error) {
    return config;
  }
};
