import { commifyWithDecimals } from "@presale-app/app/helpers";
import { useVoucher } from "@presale-app/app/hooks";
import { useConnectionStatus } from "@thirdweb-dev/react";
import { utils } from "ethers";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { useUser } from "../../hooks/useUser";
import { MintBtn } from "../../ui-kit";

type BalanceBlockProps = {
  onClick: () => void;
};

const BalanceBlock: React.FC<BalanceBlockProps> = ({ onClick }) => {
  const { ethBalance, usdcBalance } = useUser();
  const status = useConnectionStatus();
  const { voucher } = useVoucher();

  return (
    <div className="flex items-center sm:flex-wrap sm:gap-y-16">
      <ReactTooltip
        anchorSelect=".mint-button-tooltip"
        content={
          voucher &&
          new Date(
            Number(utils.formatUnits(voucher?.currentPresaleRound.startTimeDate, 0)) * 1000,
          ).getTime() > new Date().getTime()
            ? "Round has not started"
            : new Date(
                Number(utils.formatUnits(voucher?.currentPresaleRound.endTimeDate, 0)) * 1000,
              ).getTime() < new Date().getTime()
            ? "All rounds are over"
            : ""
        }
        place="top"
        opacity={1}
        style={{
          backgroundColor: "#182a29",
          maxWidth: "500px",
          textAlign: "center",
          borderRadius: "15px",
        }}
      />
      <MintBtn
        className="balanceBtn mint-button-tooltip"
        text="mint"
        disabled={
          voucher &&
          (new Date(
            Number(utils.formatUnits(voucher?.currentPresaleRound.startTimeDate, 0)) * 1000,
          ).getTime() > new Date().getTime() ||
            new Date(
              Number(utils.formatUnits(voucher?.currentPresaleRound.endTimeDate, 0)) * 1000,
            ).getTime() < new Date().getTime())
        }
        onClick={onClick}
      />

      {status !== "connected" && (
        <span className="text-p3 uppercase text-gray ml-8 sm:hidden sm:ml-auto sm:text-p4">
          connect your wallet
        </span>
      )}
      <div className="flex items-start gap-8 ml-auto sm:flex-[0_1_50%] sm:ml-0">
        {/* TODO_ENV: 2 - environment: MAIN_USDC_DECIMALS */}
        <span className="text-white text-sh1 sm:text-sh2">
          {commifyWithDecimals(+usdcBalance, 2)}
        </span>
        <span className="text-gray text-p3 sm:text-p4">USDC</span>
      </div>
      <div className="flex items-start gap-8 ml-24 sm:flex-[0_1_50%] sm:ml-0">
        {/* TODO_ENV: 5 - environment: ETH_DECIMALS */}
        <span className="text-white text-sh1 sm:text-sh2">
          {commifyWithDecimals(+ethBalance, 5)}
        </span>
        <span className="text-gray text-p3 sm:text-p4">ETH</span>
      </div>
    </div>
  );
};

export default BalanceBlock;
