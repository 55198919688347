import { environment } from "../../environments/environment";

export const config = {
  isDev: process.env.REACT_APP_DEVELOPMENT,
  USDC_ADDRESS: environment.USDC_ADDRESS,
  VOUCHER_ADDRESS: environment.VOUCHER_ADDRESS,
  ETHERSCAN_LINK: environment.ETHERSCAN_LINK,
  OPENSEA_LINK: environment.OPENSEA_LINK,
  DECIMAL_VALUE: environment.DECIMAL_VALUE,
  USDC_DECIMALS: environment.USDC_DECIMALS,
  MAIN_USDC_DECIMALS: environment.MAIN_USDC_DECIMALS,
  ETH_DECIMALS: environment.ETH_DECIMALS,
  ROUND_PRICE_DECIMALS: environment.ROUND_PRICE_DECIMALS,
  PERCENTAGE_DECIMALS: environment.PERCENTAGE_DECIMALS,
  DATE_FORMAT: environment.DATE_FORMAT,
  LAUNCH_PRICE: environment.LAUNCH_PRICE,
  ACTIVE_CHAIN: environment.ACTIVE_CHAIN,
  DEPOSIT_GAS_LIMIT: environment.DEPOSIT_GAS_LIMIT,
  MINT_GAS_LIMIT: environment.MINT_GAS_LIMIT,
  AFFILIATE_DEPOSIT_GAS_LIMIT: environment.AFFILIATE_DEPOSIT_GAS_LIMIT,
  AFFILIATE_MINT_GAS_LIMIT: environment.AFFILIATE_MINT_GAS_LIMIT,
  WALLET_CONNECT_PROJECT_ID: environment.WALLET_CONNECT_PROJECT_ID,
  INFURA: environment.INFURA,
  apiUrl: environment.apiUrl,
  affiliateApiUrl: environment.AFFILIATE_API_URL,
  restrictedHost: environment.RESTRICTED_PRESALE_HOST,
  SENTRY_DSN: environment.SENTRY_DSN,
};
