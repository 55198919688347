import { ConnectWallet, useAddress, useConnectionStatus } from "@thirdweb-dev/react";
import cx from "classnames";

import { ButtonProps } from "../ui-kit-types";

const ConnectWalletBtn: React.FC<Omit<ButtonProps, "text">> = ({
  className,
  onClick,
  fullWidth,
}) => {
  const connectionStatus = useConnectionStatus();
  const address = useAddress();

  if (connectionStatus === "connected") {
    return (
      <div className="sm:ml-auto">
        <ConnectWallet
          detailsBtn={() => (
            <button
              className={cx(
                "flex items-center px-16 py-8 gap-16 bg-black rounded-full transition-colors duration-300 hover:bg-vitreus-green-700 text-black",
                className,
              )}
              style={{ position: "relative" }}
            >
              <span className="text-p3 text-gray uppercase whitespace-nowrap">
                {address?.slice(0, 4)}...{address?.slice(-4)}
              </span>
              <img src="../../../assets/images/userHeader.svg" alt="user" />
            </button>
          )}
        />
      </div>
    );
  }

  return (
    <div
      className={cx(
        "flex items-center px-16 py-8 gap-16 bg-black rounded-full transition-colors duration-300 hover:bg-vitreus-green-700 text-black",
        className,
      )}
      style={{ position: "relative" }}
    >
      <ConnectWallet
        btnTitle=" "
        style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, opacity: 0 }}
      />

      <span className="text-p3 text-gray uppercase whitespace-nowrap">
        connect <span className="sm:hidden">your wallet</span>
      </span>
      <img src="../../../assets/images/userHeader.svg" alt="user" />
    </div>
  );
};

export default ConnectWalletBtn;
