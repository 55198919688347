/* eslint-disable react/no-unescaped-entities */
import { Link, PrimaryBtn } from "@presale-app/app/ui-kit";
import React, { useRef, useState } from "react";

import { ModalProps } from "../types";

const DisclaimerModal: React.FC<ModalProps> = ({ setActiveModal = () => {} }) => {
  const popupScrollable = useRef(null);
  const [disabled, setDisabled] = useState(true);

  const handleScroll = (e: React.UIEvent<HTMLParagraphElement>) => {
    const target = e.target as HTMLElement;
    if (target.scrollTop > 0.9 * (target.scrollHeight - target.offsetHeight)) {
      setDisabled(false);
    }
  };

  return (
    <div className="fixed top-0 left-0 flex justify-center items-center w-full h-full sm:h-[calc(100*var(--vh,_1vh)_-_180rem)] sm:mt-[90rem] sm:overflow-auto sm:items-start">
      <div className="w-[420rem] flex flex-col justify-between bg-vitreus-green-800 rounded-large animate-fade-in p-24 text-white mx-20">
        <div className="text-p3 pb-20 uppercase border-b border-b-vitreus-green-600 text-center">
          NFT Purchase Agreement
        </div>
        <p
          ref={popupScrollable}
          className="text-p2 my-20 h-[350rem] overflow-y-scroll"
          onScroll={handleScroll}
        >
          <b>DISCLAIMER: PLEASE READ CAREFULLY BEFORE PROCEEDING</b>
          <br />
          <br />
          By participating in the VTRS Progressive Presale, you acknowledge and agree to be bound by
          the terms and conditions set forth in the <b>"NFT Purchase & License Agreement"</b>{" "}
          (hereinafter referred to as the "Agreement"). The Agreement governs your purchase and use
          of the non-fungible token (NFT) being offered in this presale event.
          <br />
          <br />
          Before making a purchase, it is essential that you review the entire Agreement. The
          Agreement outlines the rights and obligations of both the purchaser (referred to as "you",
          "the user" or "buyer") and the issuer (referred to as "we", "the company" or "seller") in
          relation to the NFT, including but not limited to ownership rights, licensing terms,
          limitations, and disclaimers of liability.
          <br />
          <br />
          To access the complete Agreement, please click on the following link:{" "}
          <Link
            className="px-[0] py-0 lowercase text-white underline"
            textStyle="text-p2"
            to="https://vitreus.io/vtrs-nft-purchase-agreement/"
            text="https://vitreus.io/vtrs-nft-purchase-agreement/"
            blank
          />
          .
          <br />
          <br />
          By proceeding with the purchase, you confirm that you have read, understood, and agreed to
          all the terms and conditions set forth in the Agreement. If you do not agree with any part
          of the Agreement or are unable to comply with its terms, please refrain from participating
          in the VTRS Progressive Presale.
          <br />
          <br />
          Please note that this pop-up box is intended solely to provide a convenient link to the
          Agreement and does not represent the entire content of the Agreement. The Agreement itself
          is a legally binding contract between you and the company.
          <br />
          <br />
          We strongly recommend that you seek independent legal advice before engaging in any NFT or
          token purchase or entering into contractual agreements of this nature.
          <br />
          <br />
          Thank you for your attention to this disclaimer, and we appreciate your cooperation.
        </p>
        <PrimaryBtn
          className="uppercase"
          text="accept"
          onClick={() => {
            localStorage.setItem("disclaimer", "true");
            setActiveModal("");
          }}
          disabled={disabled}
          fullWidth
        />
      </div>
    </div>
  );
};

export default DisclaimerModal;
