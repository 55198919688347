import { useMutation } from "@tanstack/react-query";
import { SmartContract } from "@thirdweb-dev/react";
import { BaseContract } from "ethers/lib/ethers";

import { config } from "../config/environment.config";

export const useApproveUsdc = (contract?: SmartContract<BaseContract>) =>
  useMutation(async ({ value }: { value: string }) => {
    return await contract?.erc20.setAllowance(config.VOUCHER_ADDRESS, Number(value));
  });
