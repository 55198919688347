/* eslint-disable camelcase */
import { useQuery } from "@tanstack/react-query";
import { BigNumber, constants, Contract, providers, utils } from "ethers/lib/ethers";

import vitreusVoucherJSON from "../abi/VitreusVoucher.json";
import { config } from "../config/environment.config";
import { floor, formatUsdcFloor, pow, round } from "../helpers";
import { CurrentVoucher } from "../models/CurrentVoucher";
import { PresaleRound } from "../models/PresaleRound";

const getCurrentVoucher = async (
  contract: Contract,
  presaleRounds: Record<string, PresaleRound>,
  address?: string,
) => {
  if (!address) return null;

  const id = utils.formatUnits(await contract.tokenOf(address), 0);
  if (id === "0") return null;

  const values = await contract.statsOf(id);

  return new CurrentVoucher(
    {
      id,
      values,
    },
    presaleRounds,
  );
};

export const useGetVoucher = (provider?: providers.Provider, address?: string) =>
  useQuery(
    ["getVoucher", address],
    async () => {
      const contract = new Contract(config.VOUCHER_ADDRESS, vitreusVoucherJSON.abi, provider);
      const { estimateUSDCAmount, estimateETHAmount } = contract;
      const currentPresaleRound = new PresaleRound(await contract.getCurrentPresaleRound());
      const currentPresaleRoundNumber = utils.formatUnits(
        (await contract.presaleRoundNumber()).add(BigNumber.from(1)),
        0,
      );
      // TODO_ENV: 4 - environment: ROUND_PRICE_DECIMALS
      const currentPresaleRoundPrice = round(+currentPresaleRound.price, 4);
      // TODO_ENV: 2 - environment: PERCENTAGE_DECIMALS
      const currentPresaleRoundDiscount = (+currentPresaleRound.discount).toFixed(2);

      const presaleRoundsResponse = await contract.getAllPresaleRounds();
      const presaleRounds: Record<string, PresaleRound> = presaleRoundsResponse.reduce(
        (acc: Record<string, PresaleRound>, round: any, index: number) => ({
          ...acc,
          [index]: new PresaleRound(round),
        }),
        {} as Record<string, PresaleRound>,
      );

      const currentVoucher = await getCurrentVoucher(contract, presaleRounds, address);

      const nextRound = presaleRounds[currentPresaleRoundNumber];
      const totalProceeds = formatUsdcFloor(
        presaleRoundsResponse
          .slice(0, Number(currentPresaleRoundNumber))
          .reduce(
            (acc: BigNumber, { totalDeposits }: { totalDeposits: BigNumber }) =>
              acc.add(totalDeposits),
            constants.Zero,
          ),
      );
      // TODO_ENV: 4 - environment: ROUND_PRICE_DECIMALS
      const nextPrice = nextRound ? round(+nextRound.price, 4) : currentPresaleRoundPrice;
      const allPresaleRoundCount = utils.formatUnits(await contract.getPresaleRoundAmount(), 0);
      // TODO_ENV: 2 - environment: VTRS_DECIMALS
      const currentPresaleRoundSoldVtrs = round(+currentPresaleRound.soldVtrs, 2);
      // TODO_ENV: 2 - environment: VTRS_DECIMALS
      const currentPresaleRoundAllVtrs = round(+currentPresaleRound.allVtrs, 2);

      const availableDepositNumber = await contract.availableDeposit(currentVoucher?.id || 0);
      const availableDeposit = formatUsdcFloor(availableDepositNumber);

      const { _hex } = await estimateETHAmount(Number(availableDepositNumber));
      const bigNumber = utils.formatUnits(_hex, 0);
      const availableDepositNumberInETH = Number(bigNumber) / pow(10, 18);

      // TODO_ENV: 2 - environment: PERCENTAGE_DECIMALS
      const vtrsSoldPercentage = (+floor(
        (Number(currentPresaleRound.soldVtrs) / Number(currentPresaleRound.allVtrs)) * 100,
        2,
      )).toFixed(2);

      return {
        contract,
        currentPresaleRound,
        currentPresaleRoundNumber: currentPresaleRoundNumber,
        currentPresaleRoundSoldVtrs,
        currentPresaleRoundAllVtrs,
        currentPresaleRoundPrice,
        currentPresaleRoundDiscount,
        allPresaleRoundCount,
        totalProceeds,
        nextPrice,
        presaleRounds,
        currentVoucher,
        availableDeposit,
        availableDepositNumber: availableDepositNumber / pow(10, config.USDC_DECIMALS),
        availableDepositNumberInETH,
        vtrsSoldPercentage,
        estimateUSDCAmount,
        estimateETHAmount,
      };
    },
    { enabled: !!provider },
  );
