import cx from "classnames";
import { useState } from "react";

import useMobileHeight from "../actions/useMobileHeight";
import BackImages from "../components/BackImages/BackImages";
import Header from "../components/Header/Header";
import Main from "../components/Main/Main";
import MobileFooter from "../components/MobileFooter/MobileFooter";

const Landing = () => {
  useMobileHeight();

  const checkDisclaimer = () => {
    if (localStorage.getItem("disclaimer")) {
      return "";
    }

    return "disclaimerModal";
  };
  const [activeModal, setActiveModal] = useState<string>(() => checkDisclaimer());

  return (
    <div
      className={cx("landing pt-72 bg-black flex-auto flex flex-col items-stretch", {
        "h-[100vh] overflow-hidden": activeModal !== "",
      })}
    >
      <Header />
      <Main
        className="mt-[130rem] sm:mt-[50rem] flex-auto z-[2]"
        activeModal={activeModal}
        setActiveModal={setActiveModal}
      />
      <BackImages />
      <MobileFooter />
    </div>
  );
};

export { Landing };
