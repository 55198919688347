import { Web3Button } from "@thirdweb-dev/react";
import cx from "classnames";

import vitreusVoucherJSON from "../../abi/VitreusVoucher.json";
import { config } from "../../config/environment.config";
import { MintButtonProps } from "../ui-kit-types";

const MintBtn: React.FC<MintButtonProps> = ({
  className,
  onClick,
  text,
  refresh,
  fullWidth,
  rounded = true,
  disabled,
  onSuccess,
  onError,
}) => {
  const mint = (
    <Web3Button
      className={cx("mintBtn text-p3", className, {
        "rounded-small": rounded,
        "w-full": fullWidth,
      })}
      contractAbi={vitreusVoucherJSON.abi}
      contractAddress={config.VOUCHER_ADDRESS}
      action={onClick}
      onSuccess={onSuccess}
      onError={onError}
      isDisabled={disabled}
    >
      {text}
    </Web3Button>
  );

  if (refresh) {
    return <div onMouseEnter={refresh}>{mint}</div>;
  }

  return mint;
};

export default MintBtn;
