import { useContract } from "@thirdweb-dev/react";

import IERC20 from "../abi/IERC20.json";
import { useApproveUsdc } from "../actions/useApprove";
import { useGetUSDC } from "../actions/useGetUSDC";
import { config } from "../config/environment.config";

export const useUSDC = () => {
  const { contract, isLoading: contractLoading } = useContract(config.USDC_ADDRESS, IERC20.abi);
  const { data, isLoading: allowanceLoading, refetch } = useGetUSDC(contract);
  const { mutateAsync, isLoading } = useApproveUsdc(contract);

  return {
    allowance: data?.displayValue ? Number(data?.displayValue) : 0,
    refetchAllowance: refetch,
    approve: mutateAsync,
    isLoading: contractLoading || isLoading || allowanceLoading,
  };
};
