import { OutlineBtn } from "@presale-app/app/ui-kit";

import { ModalProps } from "../types";

const ApproveModal: React.FC<ModalProps> = ({ setActiveModal = () => {} }) => {
  return (
    <div className="fixed top-0 left-0 flex justify-center items-center w-full h-full">
      <div className="w-[500rem] min-h-[300rem] flex flex-col items-center justify-between bg-vitreus-green-800 rounded-large animate-fade-in p-40 mx-20">
        <span className="text-sh2 text-vitreus-luminous-green my-16">Approvement successful</span>
        <span className="text-p1 text-gray mb-60 text-center">
          Congratulations! Your approval for USDC spending has been successful. You can now proceed
          with your intended transaction.
        </span>
        <OutlineBtn className="w-1/2 mt-20" text="Got it" onClick={() => setActiveModal("")} />
      </div>
    </div>
  );
};

export default ApproveModal;
